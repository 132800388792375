/* eslint-disable no-console,react/no-multi-comp */
import * as React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import PageTransition from "gatsby-plugin-page-transitions"
import { Col, Row, Layout } from "antd"

import "antd/lib/style"
import "../assets/styles"
import CustomFooter from "../components/commonFooter"

const { Content } = Layout

class Template extends React.Component {
  render() {
    return (
      <div className="site">
        <Helmet>
          <html className={null} />
          <title itemProp="name" lang="es">
            Cloud Process History
          </title>
        </Helmet>

        <Layout>
          <Content style={{ margin: "2em 16px" }}>
            <Row>
              <Col span={8} offset={8}>
                <PageTransition>
                  <div className="content">{this.props.children}</div>
                </PageTransition>
              </Col>
            </Row>
          </Content>

          <CustomFooter />
        </Layout>
      </div>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Template
