import React from "react"
import { Col, Button, Row } from "antd"
import { navigate } from "gatsby-link"
import { useIntl } from "gatsby-plugin-intl"

import Template from "../layouts/clean"
import image from "../assets/images/404.gif"
import withAuth from "../components/withAuthHoc"

const NotFoundPage = () => {
  const intl = useIntl()

  const handleBack = e => {
    e.preventDefault()
    navigate(`/home`)
  }

  return (
    <Template>
      <h1 data-testid="not-found">404</h1>
      <p>{intl.formatMessage({ id: "404-title" })}</p>
      <img
        alt="A nice submarine deep looking for the page that causes 404 error not found."
        src={image}
      />

      <Row
        type="flex"
        justify="center"
        align="top"
        style={{ marginTop: "1em" }}
      >
        <Col>
          <Button type="primary" onClick={handleBack}>
            {intl.formatMessage({ id: "back-to-home" })}
          </Button>
        </Col>
      </Row>
    </Template>
  )
}

export default withAuth(NotFoundPage)
